// axios boot file (src/boot/axios.js)

import Vue from 'vue'
import axios from 'axios'

let apiHost = 'http://localhost:9099';
if (window.location.hostname.endsWith('artpass.id')) {
  apiHost = `https://artpass.id`
}

const api = axios.create({
  baseURL: apiHost
})

Vue.prototype.$axios = axios
Vue.prototype.$api   = api
Vue.prototype.$apiHost = apiHost

// Here we define a named export
// that we can later use inside .js files:
export { axios, api, apiHost }